import React, { useState, useEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { getNavigationUrl } from 'src/utils/history';
import useStyles from './styles';

const CvBannerAd = ({ intl }) => {
  const classes = useStyles();
  const [slideAnimation, setSlideAnimation] = useState(false);

  return (
    <Link
      to={getNavigationUrl(`/cv-builder`)}
      className={classes.banner}
      onMouseEnter={() => {
        {
          setSlideAnimation(true);
        }
      }}
      onMouseLeave={() => {
        {
          setSlideAnimation(false);
        }
      }}
    >
      <picture
        className={clsx(
          classes.bannerImage,
          slideAnimation && classes.pulseAnimation
        )}
      >
        <source
          srcSet={require(`src/img/crop_banner_image.webp`)}
          type='image/webp'
        />
        <img
          loading='lazy'
          className={classes.bannerImage}
          src={require(`src/img/crop_banner_image.webp`)}
          alt={intl.formatMessage({
            id: 'img.alt.cvBuilderMan',
            defaultMessage: 'Professional CV Builder tool from Expoint.',
          })}
        />
      </picture>
      <div className={clsx(classes.bannerTitle)}>
        <p>
          {intl.formatMessage({
            id: 'cv.banner.ad.title',
            defaultMessage: `Get to the top of the 'yes list' with a standout CV!`,
          })}
        </p>
        {/* <p>
          {intl.formatMessage({
            id: 'cv.banner.ad.subtext',
            defaultMessage: 'Boost your career with a standout CV',
          })}
        </p> */}
      </div>
      <div className={clsx(classes.bannerButton)}>
        <p>
          {intl.formatMessage({
            id: 'btns.createCV',
            defaultMessage: 'CREATE CV',
          })}
        </p>
      </div>
    </Link>
  );
};

export default CvBannerAd;
