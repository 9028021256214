import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  bannerImage: {
    // position: 'absolute',
    right: 10,
    maxWidth: 110,
    minWidth: 50,
    aspectRatio: '1/1',
    height: '100%',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  banner: {
    overflow: 'hidden',
    padding: '0 15px',
    width: '100%',
    borderRadius: 6,
    height: 100,
    color: 'white',
    backgroundColor: ' #0093E9',
    backgroundImage: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    '&:hover, &:focus': {
      boxShadow: '0 4px 4px 0 rgba(32,40,92,0.2)',
    },
    '@media (max-width: 600px)': {
      height: 80,
    },
  },
  pulseAnimation: {
    animation: `$pulse 2s infinite`,
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
    },

    '70%': {
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(0.95)',
    },
  },
  bannerTitle: {
    padding: '0 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    wdith: '100%',
    '& p': {
      textAlign: 'center',
      fontSize: 15,
      '&:nth-child(1)': {
        fontWeight: 600,
        fontSize: 20,
        padding: 0,
      },
    },
    '@media (max-width: 1275px)': {
      '& p': {
        fontSize: 14,
        '&:nth-child(1)': {
          fontWeight: 600,
          fontSize: '16px !important',
          padding: 0,
        },
      },
    },
  },
  bannerButton: {
    cursor: 'pointer',
    color: '#20285C',
    padding: '5px 10px',
    backgroundColor: '#FFFFFF',
    padding: '8px 20px',
    fontWeight: 700,
    borderRadius: 15,
    textTransform: 'uppercase',
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',

    '& p': { textAlign: 'center', fontSize: 16 },
    '@media (max-width: 1275px)': {
      '&': {
        padding: '7px 20px',
      },
      '& p': {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    '@media (max-width: 600px)': {
      '&': {
        padding: '6px 8px',
      },
      '& p': {
        fontSize: 13,
        fontWeight: 600,
      },
    },
  },
}));
